(function ($) {
    function fixFooterPadder() {
        var bodyWerapper = $('.outer-wrapper');
        var footer = $('footer.footer');
        bodyWerapper.css('padding-bottom', footer.outerHeight() + 'px');
    }

    $(document).ready(fixFooterPadder);
    $(window).ready(fixFooterPadder);
    $(window).resize(fixFooterPadder);
})(jQuery);