(function ($) {
    function toggleSubMenu(e) {
        e.preventDefault();

        var elem = $(this);

        var ul = elem.closest('li').find('ul').first();
        ul = $(ul);

        if (elem.hasClass('expanded')) {
            ul.slideUp();
        } else {
            ul.slideDown();
        }
        elem.toggleClass('expanded');
    }

    function toggleMobileMenu(e) {
        e.preventDefault();
        var topNavContainer = $('.top-nav');
        if (topNavContainer.hasClass('open')) {
            topNavContainer.slideUp();
        } else {
            topNavContainer.slideDown();
        }
        topNavContainer.toggleClass('open');
    }

    function cleanupMobileMenu() {
        var topNav = $('.top-nav');
        var breakPoint = 1090;

        if ($(window).width() >= breakPoint) {
            topNav.css('display', '');
            topNav.removeClass('open');
            topNav.find('ul').css('display', '');
            topNav.find('.expanded').removeClass('expanded');
        }
    }

    function initMobileMenu() {
        var linkContainer = $('.top-nav-toggle');
        var toggle = linkContainer.find('a');
        var topNav = $('.top-nav');

        var linkWithChildren = topNav.find('.menu-item-has-children');

        linkWithChildren.each(function (key, elem) {
            var elemLink = $(elem).find('a').first();
            var link = $('<a href="#" />').addClass('expander');
            var div = $('<div />');
            var svgLink = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            var useLink = document.createElementNS("http://www.w3.org/2000/svg", "use");
            useLink.setAttributeNS("http://www.w3.org/1999/xlink", 'href', '#symbol-down-arrow');

            link.on('click', toggleSubMenu);

            svgLink.appendChild(useLink);
            link.append(div);
            div.append(svgLink);
            link.insertAfter(elemLink);
        });

        toggle.on('click', toggleMobileMenu);
    }

    $(document).ready(initMobileMenu);
    $(window).resize(cleanupMobileMenu);
})(jQuery);